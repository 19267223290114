import {formatInTimeZone} from "date-fns-tz";
import enGB from "date-fns/locale/en-GB";
import {isEmpty} from "lodash";
import {DefaultTimezone} from "./constants";

export const formatDateInTimezone = (date: Date | number | string, timezone: string, dateFormat: string): string => {
    const formattedTimezone = isEmpty(timezone) ? DefaultTimezone : timezone;
    return formatInTimeZone(
        new Date(date),
        formattedTimezone,
        dateFormat,
        {locale: enGB}
    )
}

export function combineProposedCallDateAndTime(selectedDate: Date, selectedTime: Date) {
        const date = new Date(selectedDate);
        const time = new Date(selectedTime);
        const hours = time.getHours();
        const minutes = time.getMinutes();
        date.setHours(hours);
        date.setMinutes(minutes);

        return date;
}

export const getFormattedTimeInZone = (
    time: Date,
    timezone: string,
    dateFormat: string
): string => {
    return formatInTimeZone(time, timezone, dateFormat, {
        locale: enGB,
    });
};
