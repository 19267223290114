import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import SortableAutocomplete from "../../SortableAutocomplete";

interface AddEditArticlePageProps {
  index: number;
  isContentExist: boolean;
  getFieldProps: (name: string) => any;
  setFieldValue: (name: string, value: any) => void;
  contentUnitOptions: Array<string>;
}

export function AddEditArticlePage({
  index,
  isContentExist,
  getFieldProps,
  setFieldValue,
  contentUnitOptions,
}: AddEditArticlePageProps) {
  const [childrenIds, setChildrenIds] = useState<Array<string>>([]);

  let thumbnailLabel = "Page image name";
  let descriptionLabel = "Page Description";

  if (isContentExist) {
    thumbnailLabel = `${thumbnailLabel}# ${index + 1}`;
    descriptionLabel = `${descriptionLabel}# ${index + 1}`;
  }

  useEffect(() => {
    const defaultChildrens = getFieldProps(`pages.${index}.exercises`).value;
    if (defaultChildrens && defaultChildrens.length > 0) {
      setChildrenIds(defaultChildrens);
    }
  }, [getFieldProps, index]);

  return (
    <Stack key={index} spacing={3}>
      <TextField
        sx={{ width: "100%" }}
        label={thumbnailLabel}
        defaultValue={""}
        {...getFieldProps(`pages.${index}.thumbnail`)}
        type={"text"}
      />

      <TextField
        sx={{ width: "100%" }}
        multiline
        label={descriptionLabel}
        {...getFieldProps(`pages.${index}.markdown`)}
        defaultValue={""}
        type={"text"}
      />

      <SortableAutocomplete
        selectedIds={childrenIds}
        setSelectedIds={(newSelectedIds) => {
          setChildrenIds(newSelectedIds(childrenIds));
          setFieldValue(
            `pages.${index}.exercises`,
            newSelectedIds(childrenIds)
          );
        }}
        contentUnitOptions={contentUnitOptions}
      />
    </Stack>
  );
}
