import { InvoiceStatus, TherapistInvoice } from "../../types/therapists";
import {
  NewRenewalChallenge,
  RenewalChallenge,
} from "../../types/invitation/renewalChallenge";
import { firebaseFunctions } from "contexts/FirebaseContext";
import firebase from "firebase";
import { CounsellorSearchFilter } from "../../types/CounsellorSearchFilters";
import { PrimitiveIdentifier } from "pages/users/migration/types/Identifier";
import {
  AudioMeditationGenerationRequestType,
  AudioMeditationPreviewGenerationRequestType,
  MixAudioMeditationsGenerationRequestType,
} from "../../types/audioMeditationGenerations";
import { NotificationPayload } from "../../types/notifications";
import {
  GenerateAndSaveAIBasedTranslationType,
  MultiLingualContentGenerationType,
} from "../../types/translations";
import { SettingMultiLingualRequestType } from "../../types/settings";
import { MFAStatus } from "hooks/useMFAStatus";
import { OpenAITokenCountRequestType } from "../../types/aiPrompts";

export const updateRole = firebaseFunctions.httpsCallable("updateRole");
export const getVirgilJwt = firebaseFunctions.httpsCallable("getVirgilJwt");
export const getUsers = firebaseFunctions.httpsCallable("getAllUsersWithCode");
export const getUserRoles = firebaseFunctions.httpsCallable("getRolesOfUser");
export const getCorporateDashboardPermissionData =
  firebaseFunctions.httpsCallable("getInvitationCodeGroupOfUser");

export const updateCorporateDashboardPermissions =
  firebaseFunctions.httpsCallable("updateCorporateDashboardPermissions");

export const editCode = firebaseFunctions.httpsCallable("editInvitationCode");

export const createCode = firebaseFunctions.httpsCallable(
  "createInvitationCode"
);

export const duplicateCode = firebaseFunctions.httpsCallable(
  "duplicateInvitationCode"
);

export const createRegistrationCode = firebaseFunctions.httpsCallable(
  "createRegistrationCode"
);

export const editRegistrationCode = firebaseFunctions.httpsCallable(
  "editRegistrationCode"
);

export const createInvitationCodeCreditsPeriod =
  firebaseFunctions.httpsCallable("createInvitationCodeCreditsPeriod");

export const createTherapist =
  firebaseFunctions.httpsCallable("createTherapist");
export const editTherapist = firebaseFunctions.httpsCallable("editTherapist");
export const syncEdit = firebaseFunctions.httpsCallable(
  "syncTherapistWithRole"
);
export const deleteTherapist =
  firebaseFunctions.httpsCallable("deleteTherapist");
export const validateAdmin = firebaseFunctions.httpsCallable("validateAdmin");
export const updateSetting = firebaseFunctions.httpsCallable("updateSettings");
export const createVersionCode =
  firebaseFunctions.httpsCallable("changeVersionCode");
export const createOrUpdateRoom = firebaseFunctions.httpsCallable(
  "conferenceCall-createOrUpdateRoom"
);
export const getAlgoliaSearchKey = firebaseFunctions.httpsCallable(
  "getAlgoliaSearchKey"
);
export const deleteRoom = firebaseFunctions.httpsCallable(
  "conferenceCall-deleteRoom"
);
export const validateGoogleUser =
  firebaseFunctions.httpsCallable("validateGoogleUser");
export const getParticipantsTimezone = firebaseFunctions.httpsCallable(
  "getParticipantsTimezone"
);

export const reindexAlgoliaTherapist =
  firebaseFunctions.httpsCallable("reindexTherapists");

export const reindexBlendedCare =
  firebaseFunctions.httpsCallable("reindexBlendedCare");

export const reIndexAlgoliaAppTranslation = firebaseFunctions.httpsCallable(
  "algoliaReindexAppTranslations"
);

export const resetMFA = firebaseFunctions.httpsCallable("resetMFA");

export const verifyPhoneNumber =
  firebaseFunctions.httpsCallable("verifyPhoneNumber");

export function addAllMissingBioTranslations(): Promise<firebase.functions.HttpsCallableResult> {
  return firebaseFunctions.httpsCallable("addAllMissingBioTranslations")();
}

export const updateVideoCall =
  firebaseFunctions.httpsCallable("updateVideoCall");

export const getUserCreditsInfo =
  firebaseFunctions.httpsCallable("getUserCreditsInfo");

export const updateExtraCredits =
  firebaseFunctions.httpsCallable("updateExtraCredits");

export const deleteUser = firebaseFunctions.httpsCallable("deleteUser");

export const closeDeleteRequest =
  firebaseFunctions.httpsCallable("closeDeleteRequest");

export const incrementContentVersion = firebaseFunctions.httpsCallable(
  "incrementContentVersion"
);

export const editOrganisation =
  firebaseFunctions.httpsCallable("editOrganisation");

export const createOrganisation =
  firebaseFunctions.httpsCallable("createOrganisation");

export const deleteOrganisation =
  firebaseFunctions.httpsCallable("deleteOrganisation");

export const creatInvitationCodeGroup = firebaseFunctions.httpsCallable(
  "createInvitationCodeGroup"
);

export const editInvitationCodeGroup = firebaseFunctions.httpsCallable(
  "editInvitationCodeGroup"
);

export const deleteInvitationCodeGroup = firebaseFunctions.httpsCallable(
  "deleteInvitationCodeGroup"
);

const createRenewalChallengeFunction = firebaseFunctions.httpsCallable(
  "renewalChallenge-create"
);

export const migrateTranslations = firebaseFunctions.httpsCallable(
  "migrations-migrateTranslations", {
      timeout: 540000,
    }
);

export const createRenewalChallenge = (
  challenge: NewRenewalChallenge,
  invitationCode: string
): Promise<unknown> => {
  return createRenewalChallengeFunction({ challenge, invitationCode });
};

const listRenewalChallengesFunction = firebaseFunctions.httpsCallable(
  "renewalChallenge-list"
);

export const listRenewalChallenges = async (
  invitationCode: string
): Promise<RenewalChallenge[]> => {
  const { data } = await listRenewalChallengesFunction({ invitationCode });
  const challengeList = data.data;

  for (const challenge of challengeList) {
    challenge.hardLockFrom = new Date(challenge.hardLockFrom);
    challenge.softLockFrom = new Date(challenge.softLockFrom);
  }

  return challengeList;
};

const updateRenewalChallengeFunction = firebaseFunctions.httpsCallable(
  "renewalChallenge-update"
);
export const updateRenewalChallenge = async (
  invitationCode: string,
  challenge: RenewalChallenge
): Promise<void> => {
  await updateRenewalChallengeFunction({
    invitationCode,
    challenge,
  });
};

const sendInvoiceFunction = firebaseFunctions.httpsCallable("sendInvoice");
export const sendInvoiceToOps = async (
  invoiceDetails: TherapistInvoice
): Promise<void> => {
  await sendInvoiceFunction({
    fullName: invoiceDetails.fullName,
    address: invoiceDetails.address,
    street: invoiceDetails.street,
    houseNumber: invoiceDetails.houseNumber,
    postCode: invoiceDetails.postCode,
    state: invoiceDetails.state,
    country: invoiceDetails.country,
    invoiceNumber: invoiceDetails.invoiceNumber,
    dateSubmitted: invoiceDetails.dateSubmitted,
    chatHours: invoiceDetails.chatHours,
    valueChatHour: invoiceDetails.valueChatHour,
    videoHours: invoiceDetails.videoHours,
    valueVideoHour: invoiceDetails.valueVideoHour,
    additionalCostsValue: invoiceDetails.additionalCostsValue,
    totalAmount: invoiceDetails.totalAmount,
    accountHolder: invoiceDetails.accountHolder,
    bankCurrency: invoiceDetails.bankCurrency,
    bankName: invoiceDetails.bankName,
    iban: invoiceDetails.iban,
    bic: invoiceDetails.bic,
    swift: invoiceDetails.swift,
    routingNumber: invoiceDetails.routingNumber,
    comments: invoiceDetails.comments,
    month: invoiceDetails.month,
    year: invoiceDetails.year,
    status: invoiceDetails.status,
    wiseEmail: invoiceDetails.wiseEmail,
    sendToOpsOnly: true,
  });
};

const updateInvoiceStatusFunction = firebaseFunctions.httpsCallable(
  "changeInvoiceStatus"
);

export async function updateInvoiceStatus({
  therapistId,
  invoiceId,
  newStatus,
}: {
  therapistId: string;
  invoiceId: string;
  newStatus: InvoiceStatus;
}) {
  return updateInvoiceStatusFunction({
    therapistId,
    invoiceId,
    newStatus,
  });
}

const updateCounsellorSearchFilterFunction = firebaseFunctions.httpsCallable(
  "counsellorSearchFilters-update"
);

export async function updateCounsellorSearchFilter(
  filter: CounsellorSearchFilter
) {
  return updateCounsellorSearchFilterFunction({ data: filter });
}

const migrateUsersFunction = firebaseFunctions.httpsCallable(
  "migrations-migrateUsers"
);

export function migrateUsers(
  newInvitationCode: string,
  identifiers?: PrimitiveIdentifier[],
  oldInvitationCode?: string
) {
  return migrateUsersFunction({
    data: { identifiers, newInvitationCode, oldInvitationCode },
  });
}

const validateUsersFunction = firebaseFunctions.httpsCallable(
  "migrations-validateUsers"
);

/**
 * Check if a list of identifiers can be migrated.
 */
export function validateUsers(identifiers: PrimitiveIdentifier[]) {
  return validateUsersFunction({ data: { identifiers } });
}

const rollbackRevisionFunction = firebaseFunctions.httpsCallable(
  "documentRevisions-rollback"
);

export function rollbackRevision(id: string) {
  return rollbackRevisionFunction({ data: { id } });
}

const downloadPdfInvoiceFunction = firebaseFunctions.httpsCallable(
  "downloadCounsellorInvoice"
);

export async function downloadPdfInvoice({
  counsellorId,
  invoiceId,
}: {
  counsellorId: string;
  invoiceId: string;
}) {
  return downloadPdfInvoiceFunction({
    data: { counsellorId, invoiceId },
  });
}

export const getUserInfoFunction =
  firebaseFunctions.httpsCallable("getUserInfo");

// function to send push notifications to users
const sendNotificationToInvitationCodeUsersFunction =
  firebaseFunctions.httpsCallable("sendNotificationToInvitationCodeUsers");

export const sendNotificationToInvitationCodeUsers = (
  payload: NotificationPayload
) => {
  return sendNotificationToInvitationCodeUsersFunction(payload);
};

/**
 * Python Cloud function for Text To Voice Translation
 */
const generateAudioMeditationFunction = firebaseFunctions.httpsCallable(
  "generate_audio_meditation",
  { timeout: 540000 }
);

const generatePreviewAudioMeditationFunction = firebaseFunctions.httpsCallable(
  "generate_preview_audio_meditation",
  { timeout: 540000 }
);

const mixUpdatedAudioMeditationsFunction = firebaseFunctions.httpsCallable(
  "mix_updated_audio_meditations",
  { timeout: 540000 }
);

export const generateAudioMeditation = async (
  audioMeditationRequest: AudioMeditationGenerationRequestType
): Promise<void> => {
  await generateAudioMeditationFunction({
    ...audioMeditationRequest,
  });
};

export const generatePreviewAudioMeditation = async (
  audioMeditationRequest: AudioMeditationPreviewGenerationRequestType
) => {
  return generatePreviewAudioMeditationFunction({
    ...audioMeditationRequest,
  });
};

export const mixUpdatedAudioMeditations = async (
  audioMeditationRequest: MixAudioMeditationsGenerationRequestType
): Promise<void> => {
  await mixUpdatedAudioMeditationsFunction({
    ...audioMeditationRequest,
  });
};

/**
 * Python Cloud function for Translation
 */
const generateAndSaveAIBasedTranslationsFunction =
  firebaseFunctions.httpsCallable("generate_and_save_AI_based_translations", {
    timeout: 540000,
  });

const generateAndSaveMultilingualContent = firebaseFunctions.httpsCallable(
  "generate_and_save_multilingual_content",
  { timeout: 540000 }
);

const translateJSONKeys = firebaseFunctions.httpsCallable(
  "translate_json_keys",
  { timeout: 3600000 }
);

const generateAndSaveEmailAndNotificationsContent =
  firebaseFunctions.httpsCallable(
    "generate_and_save_emails_and_notifications_multilingual_content",
    { timeout: 540000 }
  );

const getOpenAITokenCountFunction = firebaseFunctions.httpsCallable(
  "getOpenAITokenCount"
);

export const getAllLanguageTranslationsInSpecificLocale =
  firebaseFunctions.httpsCallable("getAllLanguageTranslationsInSpecificLocale");

export const generateAndSaveAIBasedTranslations = async (
  aiTranslationRequest: GenerateAndSaveAIBasedTranslationType
): Promise<void> => {
  await generateAndSaveAIBasedTranslationsFunction({
    ...aiTranslationRequest,
  });
};

export const generateAndSaveMultilingualContentFunction = async (
  doc: MultiLingualContentGenerationType
): Promise<void> => {
  await generateAndSaveMultilingualContent({
    ...doc,
  });
};

export const translateJSONKeysFunction = async (doc: {
  targetLangName: string;
  targetLangKey: string;
  jsonBase64: string;
  isAnthropicAiModel: boolean;
  aiPrompt: string;
  subsetCount: number;
}) => {
  return translateJSONKeys({
    ...doc,
  });
};

export const generateAndSaveEmailAndNotificationsContentFunction = async (
  settingMultiLingualRequestType: SettingMultiLingualRequestType
): Promise<void> => {
  await generateAndSaveEmailAndNotificationsContent({
    ...settingMultiLingualRequestType,
  });
};

export const getMFAStatus = async (uid: string): Promise<MFAStatus> => {
  const getMFAStatusFunction = firebaseFunctions.httpsCallable("getMFAStatus");
  const result = await getMFAStatusFunction({ uid });
  return result.data as MFAStatus;
};

export const setSkipMFA = async (
  uid: string,
  skipMFA: boolean
): Promise<MFAStatus> => {
  const setSkipMFAFunction = firebaseFunctions.httpsCallable("setSkipMFA");
  const result = await setSkipMFAFunction({ uid, skipMFA });
  return result.data as MFAStatus;
};

export const getOpenAITokenCount = async (
  openAITokenCountRequest: OpenAITokenCountRequestType
): Promise<firebase.functions.HttpsCallableResult> => {
  return getOpenAITokenCountFunction({
    ...openAITokenCountRequest,
  });
};

export const saveOrEditRelationship = firebaseFunctions.httpsCallable(
  "saveOrEditRelationship"
);

export const createNewPaymentLink = firebaseFunctions.httpsCallable(
  "createNewPaymentLink"
);

export const getUserInfoForPaymentLink = firebaseFunctions.httpsCallable(
  "getUserInfoForPaymentLink"
);

export const getUserEmailsByIds = firebaseFunctions.httpsCallable(
  "getUserEmailsByIds"
);

export const sendTemplateEmail = firebaseFunctions.httpsCallable(
  "sendTemplateEmail"
);

export const scheduleVideoCallFunction =
    firebaseFunctions.httpsCallable("scheduleVideoCall");


export const getChatUserInfoForAdmin =
    firebaseFunctions.httpsCallable("getChatUserInfoForAdmin");
