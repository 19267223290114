/* eslint-disable @typescript-eslint/no-shadow */
import firebase from 'firebase/app';
import { db } from 'contexts/FirebaseContext';
import {CallMessage, CallStatuses, Conversation} from '../../types/chat';
import { differenceInMinutes } from 'date-fns';

import * as CloudFunctions from 'utils/firebase/cloudFunctions';

type TherapistInfo = {
  email: string;
  name: string;
  avatar: string;
};

type ChatData = {
  userId: string;
  therapistId: string;
};

type Message = {
  createdAt?: firebase.firestore.Timestamp;
  senderId?: string;
  messageId?: string;
};

export type UnreadChat = {
  chatId: string;
  therapistName: string;
  therapistAvatar: string;
  userName: string;
  unreadMessages: number;
  lastMessage: Message;
  firstUnreadMessage?: Message;
  allMessagesCount: number;
  lastMessageByTherapist?: Message;
};

type UserInfo = {
  fullname: string;
  invitationCode?: {
    code: string;
  };
};

type MessagesData = { messagesCount: number };

const getSpecificTherapist = async (therapistId: string) => {
  return db
    .collection('therapists')
    .doc(therapistId)
    .get()
    .then((snapshot) => {
      if (snapshot.data()) {
        const { email, name, avatar } = snapshot.data() as TherapistInfo;
        return { email, name, therapistId: snapshot.id, avatar };
      } else return { email: '', name: '---', therapistId, avatar: '' };
    });
};

const getSpecificUser = async (userId: string) => {
  return db
    .collection('users')
    .doc(userId)
    .get()
    .then((snapshot) => {
      if (snapshot.data()) {
        const { fullname, invitationCode } = snapshot.data() as UserInfo;
        return { fullname, invitationCode };
      } else {
        return { fullname: '---' };
      }
    });
};

const getLastMessage = async (chatId: string, senderId: string) => {
  const messages = await db
    .collection(`messages/${chatId}/conversation`)
    .where('senderId', '==', senderId)
    .orderBy('createdAt', 'desc')
    .limit(1)
    .get()
    .then((snapshot) => {
      return snapshot.docs.map((doc) => {
        return { ...(doc.data() as Message), messageId: doc.id };
      });
    });
  return messages.length ? messages[0] : {};
};

export function getAllUnreadChats() {
  return db
    .collection('chats')
    .get()
    .then(async (snapshot) => {
      const docs = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const chatData = doc.data() as ChatData;
          const chatId = doc.id;
          const unreadMessages: Message[] = await db
            .collection(`messages/${chatId}/conversation`)
            .where('received', '==', false)
            .where('senderId', '==', chatData.userId)
            .orderBy('createdAt', 'desc')
            .get()
            .then((snapshot) => {
              return snapshot.docs.map((doc) => {
                return { ...(doc.data() as Message), messageId: doc.id };
              });
            });
          let firstUnreadMessage: Message | undefined;
          let lastMessage: Message;

          // Get All messages count
          const allMessagesCount = await db
            .collection('messages')
            .doc(chatId)
            .get()
            .then((res) => {
              const { messagesCount } = (res.data() ?? { messagesCount: 0 }) as MessagesData;
              return messagesCount;
            });

          // Get last message from therapist to get last time therapist has responded
          const lastMessageByTherapist = await getLastMessage(chatId, chatData.therapistId);

          if (unreadMessages.length) {
            lastMessage = unreadMessages?.[0];
            firstUnreadMessage = unreadMessages?.[unreadMessages.length - 1];
          } else {
            // If not any unread message the get last message
            lastMessage = await getLastMessage(chatId, chatData.userId);
            firstUnreadMessage = undefined;
          }

          const therapistInfo: TherapistInfo = await getSpecificTherapist(chatData.therapistId);
          const userInfo: UserInfo = await getSpecificUser(chatData.userId);

          return {
            chatId,
            therapistName: therapistInfo.name,
            therapistAvatar: therapistInfo.avatar,
            userName: userInfo.fullname,
            unreadMessages: unreadMessages.length,
            lastMessage,
            firstUnreadMessage,
            allMessagesCount,
            lastMessageByTherapist
          };
        })
      );
      return docs;
    });
}

export const getCallStatus = (message: CallMessage) => {
  const { callAccepted, cancelled, availableTimes, callTime } = message;
  const minutesDifference = callTime
    ? differenceInMinutes(new Date(), new Date(callTime.toDate()))
    : 0;

  let callStatus: CallStatuses = 'requested';
  if (cancelled) {
    callStatus = 'cancelled';
  } else if (availableTimes?.length && !callAccepted) {
    callStatus = 'slots sent';
  } else if (callAccepted && minutesDifference < 50) {
    callStatus = 'scheduled';
  } else if (callAccepted && minutesDifference > 50) {
    callStatus = 'completed';
  }

  return callStatus;
};

export async function getAllVideoCalls() {
  let allCalls: CallMessage[] = [];
  await db
    .collection('chats')
    .get()
    .then(async (snapshot) => {
      await Promise.all(
        snapshot.docs.map(async (doc) => {
          const chatData = doc.data() as ChatData;
          const therapistInfo: TherapistInfo = await getSpecificTherapist(chatData.therapistId);
          const userInfo: UserInfo = await getSpecificUser(chatData.userId);

          const chatId = doc.id;
          const callMessages: CallMessage[] = await db
            .collection(`messages/${chatId}/conversation`)
            .where('messageType', '==', 'videoCall')
            .get()
            .then((snapshot) => {
              return snapshot.docs.map((doc) => {
                const message: CallMessage = doc.data() as CallMessage;
                const callStatus = getCallStatus(message);
                return {
                  ...message,
                  messageId: doc.id,
                  therapistName: therapistInfo.name,
                  therapistAvatar: therapistInfo.avatar,
                  userName: userInfo.fullname,
                  callStatus,
                  invitationCode: userInfo?.invitationCode?.code ?? '---'
                };
              });
            });

          allCalls = [...allCalls, ...callMessages];
        })
      );
    });
  return allCalls?.sort((a, b) =>
    new Date(a?.createdAt?.toDate()).valueOf() > new Date(b?.createdAt?.toDate()).valueOf() ? -1 : 1
  );
}


export function updateVideoCall(chatId: string, messageId: string, message: any, returnCredits: boolean) {
  return CloudFunctions.updateVideoCall({
    chatId,
    messageId,
    message,
    returnCredits
  })
}


export function deleteVideoCall(chatId: string, messageId: string) {
    return db
        .collection(`messages/${chatId}/conversation`)
        .doc(messageId)
        .delete();
}

export const getChatMessage = async (chatId: string, messageId: string) => {
     return db
        .collection(`messages/${chatId}/conversation`)
        .doc(messageId)
        .get().then((res) => {
            return res.data() as Conversation
      }).catch((error) => {
             return Promise.reject(error)
         })
}
