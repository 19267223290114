import { Autocomplete, TextField, createFilterOptions } from "@mui/material";

type Props = {
    selectedId: string,
    setSelectedId: (id: string) => void
    contentUnitOptions: string[],
    placeholder: string,
    idType: string,
    label: string,
    disabled?: boolean,
    helperText?: string
}

export default function StringAutocomplete({
  selectedId,
  setSelectedId,
  contentUnitOptions,
  placeholder = "Type another Content Unit ID",
  idType,
  label,
  disabled = false,
  helperText
}: Props) {
  const filter = createFilterOptions<string>();


  console.log("data id", selectedId, contentUnitOptions);
  console.log(!selectedId ? "undefined" : contentUnitOptions.includes(selectedId));
  // Add these debug logs
  console.log("typeof selectedId:", typeof selectedId);
  console.log("typeof contentUnitOptions:", Array.isArray(contentUnitOptions), contentUnitOptions);

  return (
    <Autocomplete
      disabled={disabled}
      id={idType}
      options={contentUnitOptions}
      value={selectedId}
      getOptionLabel={(option) => String(option)}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField 
          {...params} 
          label={label} 
          placeholder={placeholder}
          helperText={disabled ? helperText : undefined}
        />
      )}
      onChange={(_, newValue) => {
        setSelectedId(newValue ?? "");
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const inputValue = params.inputValue.trim();
        if (inputValue !== '') {
          if (typeof contentUnitOptions[0] === 'string') {
            filtered.push(inputValue);
          }
        }

        return filtered;
      }}
      
    />
  );
}
