import {  useState } from "react";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  Box,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { Icon } from "@iconify/react";

type Props = {
  selectedIds: Array<string>;
  setSelectedIds: (
    selectedIds: (prevIds: Array<string>) => string[]
  ) => void;
  contentUnitOptions: string[];
  idType?: string;
  enableSorting?: boolean;
  enableReIndex?: boolean;
};

const SortableAutocomplete = ({
  selectedIds,
  setSelectedIds,
  contentUnitOptions,
  idType = "Children",
  enableSorting = true,
  enableReIndex = true,
}: Props) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  const filter = createFilterOptions<string>();

  const handleSortStart = (index: number) => {
    setDraggedIndex(index);
  };

  const handleSortEnd = (index: number) => {
    setDraggedIndex(null);

    setSelectedIds((prevIds: Array<string>) => {
      const updatedIds = Array.from(prevIds);
      const [movedItem] = updatedIds.splice(draggedIndex!, 1);
      updatedIds.splice(index, 0, movedItem);
      return updatedIds;
    });
  };

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        label={`${idType} ids`}
        placeholder={`${idType} ids`}
        value={selectedIds.join(", ")}
      />
    );
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <Autocomplete
        multiple
        filterSelectedOptions
        options={contentUnitOptions}
        value={selectedIds}
        onChange={(event, newValue) => {
          setSelectedIds((prevIds: Array<string>) => {
            return newValue;
          });
        }}
        renderInput={renderInput}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push(params.inputValue);
          }

          return filtered;
        }}
        noOptionsText="No options"
      />

      {enableReIndex && (
        <Stack
          sx={{
            mt: 1,
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
      >
        <Button
          color="success"
          variant="contained"
          endIcon={
            <Icon icon={"fluent:arrow-sort-20-regular"} color={"black"} />
          }
          onClick={() => setOpenDialog(true)}
        >
            reIndex {idType} ids
          </Button>
        </Stack>
      )}

      <Dialog
        open={openDialog}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Reindex {idType} ids by drag and drop
        </DialogTitle>
        <DialogContent>
          <List>
            <Box mt={"m"}>
              {selectedIds.map((value, index) => (
                <ListItem
                  key={value}
                  draggable={enableSorting}
                  onDragStart={() => handleSortStart(index)}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={() => handleSortEnd(index)}
                >
                  <Box display={"flex"} mt={"s"}>
                    <Icon
                      icon={"ion:reorder-three-outline"}
                      width={25}
                      height={25}
                    />
                    <ListItemText primary={value} style={{ marginLeft: 10 }} />
                  </Box>
                </ListItem>
              ))}
            </Box>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SortableAutocomplete;
