// ----------------------------------------------------------------------

import { User } from "./account";
import { TherapistInfo } from "./therapists";
import { UserId } from "./user";

export type Contact = {
  id: string;
  name: string;
  username: string;
  avatar: string;
  address: string;
  phone: string;
  email: string;
  lastActivity: Date | string | number;
  status: string;
  position: string;
};

export type Participant = {
  id: string;
  name: string;
  username: string;
  avatar: string;
  address?: string;
  phone?: string;
  email?: string;
  lastActivity?: Date | string | number;
  status?: "online" | "offline" | "away" | "busy";
  position?: string;
};

export type TextMessage = {
  id: string;
  body: string;
  contentType: "text";
  attachments: string[];
  createdAt: Date;
  senderId: string;
};

export type ImageMessage = {
  id: string;
  body: string;
  contentType: "image";
  attachments: string[];
  createdAt: Date;
  senderId: string;
};

export type Message = TextMessage | ImageMessage;

export type SendMessage = {
  conversationId: string;
  messageId: string;
  message: string;
  contentType: "text";
  attachments: string[];
  createdAt: Date | string | number;
  senderId: string;
};

export type ChatConfig = {
  enableChat: boolean;
  usersEnabled: [string?] | [];
  versionsEnabled: [string?] | [];
};

export type VideoCallConfig = {
  enableVideoCall: boolean;
  usersEnabled: [string?] | [];
  versionsEnabled: [string?] | [];
};

export type MessageTypes = "text" | "videoCall";

type UserProfile = {
  uid: UserId;
  name: string;
  timezone: string;
};

export type UserInfo = {
  fullname: string;
  timezone: string;
};

type TherapistProfile = {
  uid: UserId;
  name: string;
  timezone: string;
  avatar: string;
};
export type Conversation = {
  _id: string | number;
  text: string;
  textEncrypted?: string;
  createdAt: Date | number | any;
  messageId: string;
  chatId: string;
  senderId: string;
  image?: string;
  video?: string;
  audio?: string;
  system?: boolean;
  sent?: boolean;
  received?: boolean;
  pending?: boolean;
  e2ee?: boolean;
  messageType?: MessageTypes;
  callTime?: Date | number | any;
  callAccepted?: boolean;
  rescheduled?: boolean;
  cancelled?: boolean;
  rescheduleTime?: Date | number | any;
  rescheduledBy?: string;
  cancelledBy?: string;
  showAlert?: boolean;
  user: {
    _id: string;
  };
  availableTimes?: Date[] | any;
  userProfile?: UserProfile;
  therapistProfile?: TherapistProfile;
  billable?: boolean;
  status?: VideoCallStatus;
  reasonNoShow?: string;
  userNoShow?: boolean;
  adminComment?: string;
  counsellorComment?: string;
  refundedCredit?: boolean;
};

type AlgoliaObjectId = {
  objectID: string;
};

export type CallMessage = {
  therapistName: string;
  therapistAvatar: string;
  userName: string;
  invitationCode: string;
  truncatedUserID: string;
  callStatus: CallStatuses;
  index: number;
} & Conversation &
  AlgoliaObjectId;

export type AlgoliaMessage = {
  createdAt: number;
  senderId: string;
  messageId: string;
};

export type ChatAlgolia = {
  objectID: string;
  invitationCode: string;
  truncatedUserID: string;
} & ChatAnalytics;

export type ChatAnalytics = {
  chatId: string;
  therapistId: string;
  therapistName: string;
  therapistAvatar: string;
  userName: string;
  lastMessage: AlgoliaMessage;
  firstUnreadMessage: AlgoliaMessage;
  allMessagesCount: number;
  lastMessageByTherapist: AlgoliaMessage;
  unreadMessages: number;
};

export type ChatItem = {
  chatId: string;
  userId: string;
  therapistId: string;
  therapistUId: UserId;
  e2ee: boolean;
  emotion?: number;
  user: User;
  therapist?: TherapistInfo;
  isNewChat?: boolean;
  schedulingCall?: boolean;
};

export type SessionItem = {
  callTime: any;
  userName: string;
  messageId: string;
  chatId: string;
  showAlert?: boolean;
  status: string;
  reasonNoShow?: string;
  adminComment?: string;
  billable?: boolean;
};

export type CallStatuses =
  | "slots sent"
  | "scheduled"
  | "requested"
  | "cancelled"
  | "completed";

export enum VideoCallStatus {
  PENDING = "pending",
  REQUESTED = "requested",
  WAITING_FOR_CLIENT_CONFIRMATION = "waitingForClientConfirmation",
  REQUEST_EXPIRED = "requestExpired",
  SCHEDULED = "scheduled",
  CANCELLED = "cancelled",
  RESCHEDULED = "rescheduled",
  // In the future we should add an ongoing status
  // ONGOING = "ongoing",
  COMPLETED = "completed",
  USER_DIDNT_JOIN = "userDidntJoin",
  THERAPIST_DIDNT_JOIN = "therapistDidntJoin",
  NO_ONE_JOINED = "noOneJoined",
  BOTH_JOINED = "bothJoined",
}

export enum ScheduleCallSteps {
  SCHEDULE = "schedule",
  CANCEL = "cancel",
  ACCEPT = "accept",
  RESCHEDULE_REQUEST = "reschedule_request",
  ACCEPT_RESCHEDULE_REQUEST = "accept_reschedule_request",
  SEND_AVAILABILITIES = "send_availabilities",
  SCHEDULE_BY_ADMIN = "schedule_by_admin",
}

export type ChatUsersInfo = {
  user: UserInfo,
  therapist: TherapistInfo,
  chatItem: ChatItem,
};
